const endPoint = 'https://kac1kiaf4m.execute-api.ap-northeast-1.amazonaws.com/v1-kimi';
const awsconfig = {
  Auth: {
    identityPoolId: 'ap-northeast-1:0be20c19-a2b4-4d0b-a49f-fabf1f926854',
    region: 'ap-northeast-1',
    identityPoolRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_5VmUMmTSb',
    userPoolWebClientId: '5ibupm9ha4voe7duf5m6tj10gl',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        name: 'CapabilityToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'TmpUrl',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'List',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'WebsocketToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Preferences',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
    ]
  }
};

export{awsconfig};
